import React, { useState, useEffect, useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import './Profile.css';
import './App.css';
import FileUpload from './FileUpload';




const Profile = ({ baseUrl, message, setMessage, messageType, setMessageType }) => {
  const { getAccessTokenSilently, user, isLoading } = useAuth0();
  
const [formData, setFormData] = useState({
  // ... Add other keys as needed
  email: '',
  resume: '',
  jobDescription: '',
  jobTitle: '',
  // ... Other potential keys can be added with default empty string values
});

  const [resume, setResume] = useState('');
  const [jobDescription, setjobDescription] = useState('');
  const [isClearing, setIsClearing] = useState(false);
  const [previousData, setPreviousData] = useState(null);
  const [clearActionInvoked, setClearActionInvoked] = useState(false);
  const [isUndoing, setIsUndoing] = useState(false);

  // State variable for the textarea focus expander
const [focusedTextArea, setFocusedTextArea] = useState('');





  const { logout } = useAuth0();

  




  // Getting the access token

  useEffect(() => {
    const fetchUserData = async () => {
      if (user && user.email) {
        // Fetch from server
        const token = await getAccessTokenSilently({
          audience: "https://dev-jobsai.us.auth0.com/api/v2/",
          scope: "openid profile email",
        });
    
        try {
          const response = await fetch(`${baseUrl}/users/find-user?email=${user.email}&auth0Id=${user.sub}&name=${user.name}`, {
            headers: {
              'Authorization': `Bearer ${token}`,
            },
          });
    
          if (response.ok) {
            const data = await response.json();
            setFormData(data);
          }
        } catch (error) {
          console.error('There was a problem fetching user data:', error);
        }
      }
    };

    fetchUserData();
  }, [user, getAccessTokenSilently, baseUrl]);


  //setting the form data
  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value || '';
    setFormData({
      ...formData,
      [name]: value
    });
  };






  // Field mappings for the form (names are weird in user schema)
  const fieldMappings = {
    'name': { label: 'Full Name', type: 'text' },
    'currentTitle': { label: 'Current Title', type: 'text' },
    'aspiringRole': { label: 'Aspiring Role', type: 'text' },
    'location': { label: 'Location', type: 'text' },
    'industry': { label: 'Industry', type: 'text' },
    'skills': { label: 'Skills', type: 'text' }, // Consider using a specialized input for arrays
    'experienceLevel': { label: 'Experience Level', type: 'text' },
    'education': { label: 'Education', type: 'text' },
    'languages': { label: 'Languages', type: 'text' }, // Consider using a specialized input for arrays
    'profilePictureUrl': { label: 'Profile Picture URL', type: 'url' },
    'resume': { label: 'Resume', type: 'text' },  // You may want to use a file input for this
    'jobDescription': { label: 'Job Description', type: 'text' },  
    'jobTitle': { label: 'Job Title', type: 'text' },
  };

  //------------------------------------------------------------------------------------

  // Set the resume text if it exists
  useEffect(() => {
    if (formData.resume) {
      setResume(formData.resume);
    }
  }, [formData]);

  // Set the job description text if it exists
  useEffect(() => {
    if (formData.jobDescription) {
      setjobDescription(formData.jobDescription);
    }
  }, [formData]);



//function that handles the update of the user profile data
const handleUpdate = useCallback(async () => {
  const token = await getAccessTokenSilently({
        audience: "https://dev-jobsai.us.auth0.com/api/v2/",
        scope: "openid profile email",
    });

    const payload = {
        email: user.email,
        auth0Id: user.sub,
        ...formData,
        resume,
        jobDescription
    };

    try {
        const response = await fetch(`${baseUrl}/users/modify-profile`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify(payload),
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const responseData = await response.json();
        console.log('User data saved successfully:', responseData);

        // Set the confirmation message and activate the fade-in animation
        setMessage('Profile updated successfully.');
        setMessageType('success');

    } catch (error) {
        console.error('There was a problem saving user data:', error);

        // Display an error message in a similar manner and activate the fade-in animation
        setMessage('There was an error updating your profile.');
        setMessageType('error');

    }

    console.log('Sending payload:', payload);


}, [getAccessTokenSilently, user, formData, baseUrl, resume, jobDescription, setMessage, setMessageType]);

  


//Clear all data

const clearAllData = async () => {

  const userConfirmed = window.confirm("Are you sure you want to clear all data?");
  
  if (!userConfirmed) {
    return;  // Exit the function if the user clicks "Cancel"
  }

  setPreviousData({
    formData: { ...formData },
    resume: resume,
    jobDescription: jobDescription
  });

  setClearActionInvoked(true);  // Set the flag to trigger undo button


  // Set the local states to blank

  setFormData({
    name: '',
    currentTitle: '',
    aspiringRole: '',
    location: '',
    industry: '',
    skills: '',
    experienceLevel: '',
    education: '',
    languages: '',
    profilePictureUrl: '',
    resume: '',
    jobDescription: '',
    jobTitle: '',
  });
  setResume('');
  setjobDescription('');

  setIsClearing(true);  // Set the flag to trigger the update

};

// Update the database when the clear data flag is set
useEffect(() => {
  if (isClearing) {
    handleUpdate();
    setIsClearing(false);
  }
}, [isClearing, formData, resume, jobDescription, handleUpdate]);



//Undo function
const handleUndo = () => {
  if (previousData) {
    setFormData(previousData.formData);
    setResume(previousData.resume);
    setjobDescription(previousData.jobDescription);

    // Set the flag to trigger the update
    setIsUndoing(true);
  }
};

useEffect(() => {
  if (isUndoing) {
    handleUpdate();

    // Reset the flags after updating
    setIsUndoing(false);
    setClearActionInvoked(false);
    setPreviousData(null);
  }
}, [isUndoing, handleUpdate]);


  //------------------------------------------------------------------------------------


  return (
    <div className='profilepage'>
      <h2 className='profileheader'>User Profile</h2>
      {isLoading ? (
        <p>Loading...</p>
      ) : (
   
   <div>
<div className='authprofileinfo'>
    <div className='auth0info'>
        <p>Name: {user?.name}</p>
        <p>Email: {user?.email}</p>
        {user?.picture && <img src={user.picture} alt="User avatar" />}
        <p>Email Verified: {String(user?.email_verified)}</p>
    </div>


    <div className="profileinstructions">
    <h3>Instructions:</h3>
    <ul className="profile-instructions-list">
        <li><strong>Resume</strong>: Use "Upload Resume" or paste manually.</li>
        <li><strong>Job Description</strong>: Paste desired role details. Include role responsibilities, title, and required skills.</li>
        <li><strong>More Info</strong>: Fill in other fields. (Optional)</li>
        <li><strong>Save & Move On</strong>: Click "Update All" to save, then explore the capabilities of JobsAI.</li>
    </ul>
</div>



</div>


      <div className='divider'></div>
          
      <div>

      <div className='update-group'>

      <h2>Tell Us About Yourself</h2> 

<button className='button-3' onClick={(e) => { e.preventDefault(); handleUpdate(); }} type="submit">Update Profile</button>

      </div>
      <form className='profileform' >



 {/* Resume Entry */}

 <div id="dropboxprofile" className='db clickable'>
        <FileUpload 
          onTextExtracted={(text) => setResume(text)} 
          style={{width: '100%', margin: '0 auto',}}
        />
        </div>

    <div className="input-form-profile">
      <div className="input-group-profile">


        <label htmlFor="resumeInput" className='inputlabel'>Resume:</label>

        
        <textarea
          id="resumeInput"
          value={resume}
          onChange={(e) => setResume(e.target.value)} // Note the change here
          placeholder="Copy & paste your resume..."
          className={`textarea-large ${focusedTextArea === 'resume' ? 'expanded' : ''}`}
          maxLength={8000}
          onFocus={() => setFocusedTextArea('resume')}
          onBlur={() => setFocusedTextArea('')}
        />




      </div>

        <div className='button-group'>
        <button className='button-2' onClick={(e) => { e.preventDefault(); handleUpdate(); }} type="submit">Save</button>
        </div>



    </div>

 {/* Job Description Entry */}
  <div className="input-form-profile">
      <div className="input-group-profile">

        <label htmlFor="jobDescriptionInput" className='inputlabel'>Interested Role Title & Description:</label>



        <input
          id="jobTitleInput"
          value={formData.jobTitle}
          onChange={(e) => setFormData({ ...formData, jobTitle: e.target.value })} // Note the change here
          placeholder="Enter the job title..."
          className='input-large'
          maxLength={100}
        />


        <textarea
          id="jobDescriptionInput"
          value={jobDescription}
          onChange={(e) => setjobDescription(e.target.value)} // Note the change here
          placeholder="Copy & paste the job description you'd like to use..."
          className={`textarea-large ${focusedTextArea === 'jobDescription' ? 'expanded' : ''}`}
          maxLength={8000}
          onFocus={() => setFocusedTextArea('jobDescription')}
          onBlur={() => setFocusedTextArea('')}
        />

      </div>



              <div className="button-group">

              <button className='button-2' onClick={(e) => { e.preventDefault(); handleUpdate(); }} type="button">Save</button>

              </div>

  </div>
        
{/* Form Entry */}
<div className='profileinput'>
      <div>
        <label htmlFor="email">Email</label>
        <input type="text" id="email" value={user ? user.email : ''} readOnly />
      </div>
      {
            Object.keys(formData)
              .filter(key => key !== '_id' && key !== '__v' && key !== 'resume' && key !== 'email' && key !== 'profilePictureUrl' && key !== 'auth0Id' && key !== 'jobDescription' && key !== 'jobTitle')  
              .map((key) => {
                const fieldInfo = fieldMappings[key] || {};
                const label = fieldInfo.label || key;
                const inputType = fieldInfo.type || 'text';
                
                return (
                  <div key={key}>
                    <label htmlFor={key}>{label}</label>
                    <input 
                      type={inputType} 
                      id={key} 
                      name={key} 
                      value={formData[key]} 
                      onChange={handleChange}
                      placeholder={`Enter your ${label}`} 
                    />
                  </div>
                );
              })
          }



            <div className='update-group'>
              <button className='button-3' onClick={(e) => { e.preventDefault(); handleUpdate(); }} type="submit">Update All</button>
              <button className='button-3' onClick={() => logout({ returnTo: window.location.origin })}>Logout</button>

                  <button className='button-3' onClick={(e) => { e.preventDefault(); clearAllData(); }}>
                    Clear All
                  </button>


                    {clearActionInvoked && (
                      <button style={{ backgroundColor: '#FFAC9A', fontWeight: '600' }} className='button-3' onClick={(e) => { e.preventDefault(); handleUndo(); }}>Undo Clear</button>
                    )}

            </div>

 </div>


    </form>

    </div>

      </div>
      )}
    </div>
  );
};

export default Profile;