import React, { useState } from 'react';
import { HashRouter as Router, Routes, Route } from 'react-router-dom'; // Changed BrowserRouter to HashRouter
import Footer from './Footer';
import Sidebar from './Sidebar';
import Dashboard from './Dashboard';
import './App.css';
import { FiMenu } from "react-icons/fi";
import CoverLetterGenerator from './CoverLetterGenerator';
import ResumeOptimization from './ResumeOptimization';
import Profile from './Profile';
import ResumeAlignment from './ResumeAlignment';
import ProtectedComponent from './ProtectedComponent';
import ResumeFeedback from './ResumeFeedback';
import InterviewQuestionsGenerator from './InterviewQuestionsGenerator';
import MessageBanner from './MessageBanner';
import LoadingBanner from './LoadingBanner';

import { CgClose } from "react-icons/cg";

function App() {
    // server url in local .env file, and in heroku config vars
    //const BASE_URL = "http://localhost:3001";
    //const BASE_URL = "https://jobsai.herokuapp.com";
    const BASE_URL = "https://jobsai-dev-9ee49127567d.herokuapp.com";
    console.log("BASE_URL:", BASE_URL);

    // mobile sidebar toggle
    const [isMobileSidebarVisible, setMobileSidebarVisible] = useState(false);
    const handleMobileHamburgerClick = (e) => {
        e.preventDefault();
        setMobileSidebarVisible(!isMobileSidebarVisible);
    };

    // desktop sidebar toggle
    const [sidebarOpen, setSidebarOpen] = useState(true);
    const toggleSidebar = () => {
        if (window.innerWidth <= 767) {
            if (sidebarOpen) {
                setSidebarOpen(!sidebarOpen);
            }
        } else {
            setSidebarOpen(!sidebarOpen);
        }
        if (window.innerWidth <= 767 && !sidebarOpen) {
            setMobileSidebarVisible(false);
        }
    };

    
//function to close message banner
const closeMessageBanner = () => {
    setMessage('');
  };

    //message banner
    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [loadingType] = useState('');
    const [loadingMessage, setLoadingMessage] = useState('');

  const closeLoadingBanner = () => {
    setIsLoading(false);
  };





    return (
        <div className="App">


            <Router>

            <Sidebar isOpen={sidebarOpen} isMobileSidebarVisible={isMobileSidebarVisible} toggleSidebar={toggleSidebar} />


            {isLoading && (
                     <LoadingBanner
                            loadingType={loadingType}
                            onClose={closeLoadingBanner}
                            loadingMessage={loadingMessage}
                            message={message}
                            isLoading={isLoading}
                        />
                )}
            
            {message && (
                    <MessageBanner
                        message={message}
                        messageType={messageType}
                        onClose={closeMessageBanner}
                    />
                )}



               

           

            <div className={`mobile-header-for-small-screens ${isMobileSidebarVisible ? '' : 'hidden'}`}>
                {isMobileSidebarVisible ? <CgClose onClick={handleMobileHamburgerClick} /> : <FiMenu onClick={handleMobileHamburgerClick} />}
            </div>
            
             

<div className={`main-container ${sidebarOpen ? "sidebar-open" : ""}`} tabIndex="0" onTouchStart={() => { if (window.innerWidth <= 767) toggleSidebar(); }} onFocus={() => { if (window.innerWidth <= 767) toggleSidebar(); }}>



<Routes>
    <Route path="/" element={<ProtectedComponent><Profile baseUrl={BASE_URL} message={message} setMessage={setMessage} setMessageType={setMessageType} messageType={messageType} onClose={closeMessageBanner} /></ProtectedComponent>} />
    <Route path="/profile" element={<ProtectedComponent><Profile baseUrl={BASE_URL} message={message} setMessage={setMessage} messageType={messageType} setMessageType={setMessageType} /></ProtectedComponent>} />
    
    <Route path="/resume-alignment" element={
    <ProtectedComponent>
        <ResumeAlignment 
            baseUrl={BASE_URL} 
            message={message} 
            setMessage={setMessage} 
            messageType={messageType} 
            setMessageType={setMessageType} 
            onClose={closeMessageBanner}
            setIsLoading={setIsLoading}
            isLoading={isLoading}
            loadingMessage={loadingMessage}
            setLoadingMessage={setLoadingMessage}
        />
    </ProtectedComponent>
} />


<Route path="/resume-feedback" element={
    <ProtectedComponent>
        <ResumeFeedback 
            baseUrl={BASE_URL} 
            message={message} 
            setMessage={setMessage} 
            messageType={messageType} 
            setMessageType={setMessageType} 
            onClose={closeMessageBanner}
            setIsLoading={setIsLoading}
            isLoading={isLoading}
            loadingMessage={loadingMessage}
            setLoadingMessage={setLoadingMessage}
        />
    </ProtectedComponent>
} />

<Route path="/interview-questions-generator" element={
    <ProtectedComponent>
        <InterviewQuestionsGenerator 
            baseUrl={BASE_URL} 
            message={message} 
            setMessage={setMessage} 
            messageType={messageType} 
            setMessageType={setMessageType} 
            onClose={closeMessageBanner}
            setIsLoading={setIsLoading}
            isLoading={isLoading}
            loadingMessage={loadingMessage}
            setLoadingMessage={setLoadingMessage}
        />
    </ProtectedComponent>
} />

<Route path="/cover-letter-generator" element={
    <ProtectedComponent>
        <CoverLetterGenerator 
            baseUrl={BASE_URL} 
            message={message} 
            setMessage={setMessage} 
            messageType={messageType} 
            setMessageType={setMessageType} 
            onClose={closeMessageBanner}
            setIsLoading={setIsLoading}
            isLoading={isLoading}
            loadingMessage={loadingMessage}
            setLoadingMessage={setLoadingMessage}
        />
    </ProtectedComponent>
} />



    <Route path="/resume-optimization" element={<ProtectedComponent><ResumeOptimization baseUrl={BASE_URL} message={message} setMessage={setMessage} setMessageType={setMessageType} messageType={messageType} onClose={closeMessageBanner} /></ProtectedComponent>} />
    <Route path="/dashboard" element={<ProtectedComponent><Dashboard baseUrl={BASE_URL} message={message} setMessage={setMessage} setMessageType={setMessageType} messageType={messageType} onClose={closeMessageBanner} /></ProtectedComponent>} />
</Routes>

                </div>
            </Router>
            <Footer />
        </div>
    );
}
export default App;
