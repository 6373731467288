import React, { useState, useEffect, useCallback } from 'react';
import FileUpload from './FileUpload';
import './App.css';
import CopyToClipboardButton from './CopyToClipboardButton';
import { useAuth0 } from "@auth0/auth0-react";
import SkeletonLoader from './SkeletonLoader';
import { AiOutlineClose } from 'react-icons/ai';
import { AiOutlinePlus } from 'react-icons/ai';
import { AiFillInfoCircle } from 'react-icons/ai';




const InterviewQuestionsGenerator = ({ baseUrl, setMessage, setMessageType, setIsLoading, isLoading, loadingMessage, setLoadingMessage }) => {
  const BASE_URL = baseUrl;


  const { getAccessTokenSilently, user } = useAuth0();

  //states set for functions
  const [resume, setResume] = useState('');
  const [jobDescription, setJobDescription] = useState('');
  const [questions, setQuestions] = useState(null);
  const [jobTitle, setJobTitle] = useState(''); // State variable for job title

  

// instructions variable and state for showing and hiding instructions
  const [showInstructions, setShowInstructions] = useState(true);


  //transition rendering of result

const [showSkeleton, setShowSkeleton] = useState(false);
const [showContent, setShowContent] = useState(false);


//state for saved questions array
const [savedQuestions, setSavedQuestions] = useState([]);


//ref for scroll to loading
const loadingRef = React.useRef(null);

//ref for scroll to saved results
const savedResultsRef = React.useRef(null);



//expandable saved results section
const [savedResultsExpanded, setSavedResultsExpanded] = useState(false);

// Flag to track whether the user has fetched saved ments before
const [hasFetchedBefore, setHasFetchedBefore] = useState(false);

// State variable for the textarea focus expander
const [focusedTextArea, setFocusedTextArea] = useState('');


  //local state for flagging processing
  const [isProcessing, setIsProcessing] = useState(false);



const questionsRef = React.useRef(null);





useEffect(() => {
  if (isLoading) {
    setShowSkeleton(true);
  } else {
    // Start fade-out of the skeleton
    setShowSkeleton(false);
    // After the skeleton fades out, start the fade-in of the content
    setTimeout(() => {
      setShowContent(true);
    }, 500);
  }
}, [isLoading]);


//deletes local storage result
const deleteLocalResult = () => {
  setQuestions(null);
  setShowContent(false);
  setShowSkeleton(false);
};


  const resetErrors = () => {
    setMessage('');
  };

  //useeffect for fetching user data from db and setting state variables for resume, job description, and job title
  useEffect(() => {
    const fetchUserData = async () => {
      if (user && user.email) {
        const token = await getAccessTokenSilently({
          audience: "https://dev-jobsai.us.auth0.com/api/v2/",
          scope: "openid profile email",
        });

        try {
          const response = await fetch(`${BASE_URL}/users/find-user?email=${user.email}&auth0Id=${user.sub}&name=${user.name}`, {
            headers: {
              'Authorization': `Bearer ${token}`,
            },
          });

          if (response.ok) {
            const data = await response.json();
            if (data.resume) {
              setResume(data.resume);
            }
            if (data.jobDescription) {
              setJobDescription(data.jobDescription);
            }
            if (data.jobTitle) {
              setJobTitle(data.jobTitle);
            }

          } else {
            const errorData = await response.json();
            setMessage(errorData.error || 'Failed to fetch user data.');
            setMessageType('error');
          }
        } catch (error) {
          setMessage(error.message);
          setMessageType('error');
        }
      }
    };
    fetchUserData();

    const savedQuestions = localStorage.getItem('questions');
    if (savedQuestions) {
      setQuestions(savedQuestions);
    }



  }, [user, getAccessTokenSilently, BASE_URL, setMessage, setMessageType]);


//save job description to db
  const saveJobDescription = async () => {
    try {
      const token = await getAccessTokenSilently({
        audience: "https://dev-jobsai.us.auth0.com/api/v2/",
        scope: "openid profile email",
      });

      const response = await fetch(`${BASE_URL}/users/modify-profile`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: user.email, jobDescription, jobTitle }), // Include jobTitle here
      });

      if (response.ok) {

      } else {
        const errorData = await response.json();
        setMessage(errorData.error || 'Failed to Save Job Description.');
        setMessageType('error');
      }
    } catch (error) {
      setMessage(error.message);
      setMessageType('error');
    }
  };

  const saveJobDescriptionButton = async () => {
    saveJobDescription();
    //if job description state has changed give success
    if (jobDescription) {
      setMessage('Job Description Saved Successfully.');
      setMessageType('success');
    }
    else {
      setMessage('Failed to Save Job Description.');
      setMessageType('error');
    }
  };



  const saveResume = async () => {
    try {
      const token = await getAccessTokenSilently({
        audience: "https://dev-jobsai.us.auth0.com/api/v2/",
        scope: "openid profile email",
      });

      const response = await fetch(`${BASE_URL}/users/modify-profile`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: user.email, resume }),
      });

      if (response.ok) {

      } else {
        const errorData = await response.json();
        setMessage(errorData.error || 'Failed to save resume.');
        setMessageType('error');
      }
    } catch (error) {
      setMessage(error.message);
      setMessageType('error');
    }
  };

  const saveResumeButton = async () => {
    saveResume();
    if (resume) {
      setMessage('Resume Saved Successfully.');
      setMessageType('success');
    } else {
      setMessage('Failed to Save Resume.');
      setMessageType('error');
    }
  };

  //clear job description and title from local
  const clearJobDescription = () => {
    setJobDescription('');
    setJobTitle('');
  };

  //clear resume from local
  const clearResume = () => {
    setResume('');
  };

  const generateInterviewQuestions = async () => {

    //if isProcessing is active, throw an error to wait till job is done
    if (isProcessing) {
      setMessage('Please wait for the current process to finish.');
      setMessageType('warning');
      return;
    }



    resetErrors();

    if (!jobDescription || !resume) {
        setMessage('Please make sure to populate both the job description and resume fields.');
        setMessageType('error');
        return;
    }


    try {
      saveResume();
      saveJobDescription();
      setIsProcessing(true);
      setIsLoading(true);
      scrollToGenerative();

        if (loadingRef.current) {
            window.scrollTo({
                top: loadingRef.current.offsetTop,
                behavior: 'smooth',
            });
        }

        const token = await getAccessTokenSilently({
            audience: "https://dev-jobsai.us.auth0.com/api/v2/",
            scope: "openid profile email",
        });

        const response = await fetch(`${BASE_URL}/generate-interview-questions`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ jobDescription, resume }),
        });

        const reader = response.body.getReader();
        const textDecoder = new TextDecoder();

        while (true) {
            const { value, done } = await reader.read();
            if (done) break;

            // Append directly to the DOM element
            if (questionsRef.current) {
                questionsRef.current.innerHTML += textDecoder.decode(value, { stream: true });
            }

            console.log("Received chunk:", textDecoder.decode(value));
        }

                // Set the alignment state variable with the content of the alignmentRef
                if (questionsRef.current) {
                  const fetchedQuestions = questionsRef.current.innerHTML;
                  setQuestions(fetchedQuestions);
                  //save to local storage
                  localStorage.setItem('questions', fetchedQuestions);
                             }

        // Set the question state variable with the content of the questionsRef
        if (questionsRef.current) {
            setQuestions(questionsRef.current.innerHTML);
        }

    } catch (error) {
        setMessage('Error fetching questions: ' + error.message);
        setMessageType('error');
    } finally {
        setIsLoading(false);
        setIsProcessing(false);
        setLoadingMessage('');
    }
}




//save job data to db and set state variable for saved questions array to include new job data
const saveJobData = async () => {
  const token = await getAccessTokenSilently({
    audience: "https://dev-jobsai.us.auth0.com/api/v2/",
    scope: "openid profile email",
  });

  const jobData = {
    userId: user.sub,
    jobTitle,
    jobDescription,
    interviewQuestions: questions,
  };

  // Check if there's already a saved question with the same job title
  if (savedQuestions.some(item => item.interviewQuestions === questions)) {
    setMessage(`Job data for ${jobTitle} already exists. Please change the job title or regenerate the existing entry.`);
    setMessageType("error");
    return; // Exit early from the function
  }

  try {
    const response = await fetch(`${BASE_URL}/users/saveJobData`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(jobData),
    });

    const responseData = await response.json();

    if (response.status === 200 || response.status === 201) {
      setMessage("Job data successfully saved.");
      setMessageType("success");
      setSavedQuestions([{ jobTitle, interviewQuestions: questions }, ...savedQuestions]);
     //clear saved result from local
      localStorage.removeItem('questions');
      setSavedResultsExpanded(true);
      deleteLocalResult();
      //scrollto saved results
      setTimeout(() => {
            window.scrollTo({
                top: savedResultsRef.current.offsetTop -0, // Offset by 25px
                behavior: 'smooth',
            });
        }
        , 50); // A short delay to ensure the section is rendered before scrolling    
      
      } else {
      setMessage(`Failed to save job data: ${responseData.message}`);
      setMessageType("error");
    }

  } catch (error) {
    setMessage(`An error occurred: ${error.message}`);
    setMessageType("error");
  }
};




  //deletes saved alignment from jobdata object
  const deleteSavedResult = async (componentName, dataIdentifier) => {

            const isConfirmed = window.confirm(`Are you sure you want to delete the data for ${dataIdentifier}?`);
          
          // If the user clicked "Cancel", then exit the function
          if (!isConfirmed) {
            return;
          }

    try {
        const token = await getAccessTokenSilently({
            audience: "https://dev-jobsai.us.auth0.com/api/v2/",
            scope: "openid profile email",
        });

        const response = await fetch(`${BASE_URL}/users/deleteSavedResult`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ userId: user.sub, componentName, dataIdentifier }),
        });

        if (response.ok) {
            // Remove the deleted alignment from the state
            setSavedQuestions(prevQuestions => prevQuestions.filter(item => item.jobTitle !== dataIdentifier));

            setMessage(`${componentName} data deleted successfully.`);
            setMessageType('success');
        } else {
            const errorData = await response.json();
            setMessage(errorData.error || `Failed to delete ${componentName} data.`);
            setMessageType('error');
        }
    } catch (error) {
        setMessage(`An error occurred: ${error.message}`);
        setMessageType("error");
    }
};


  
  
  //fetches saved alignments and job title
  
  const fetchSavedQuestions = useCallback(async () => {
    const token = await getAccessTokenSilently({
      audience: "https://dev-jobsai.us.auth0.com/api/v2/",
      scope: "openid profile email",
    });
    
    try {
      const response = await fetch(`${BASE_URL}/users/getAllJobData/${user.sub}?componentName=interviewQuestionsGenerator`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      
      const data = await response.json();
      
      // Only keep the 'jobTitle' and 'resumeAlignment' fields for each item
      const filteredData = data.jobDataList.map(item => ({
        jobTitle: item.jobTitle,
        interviewQuestions: item.interviewQuestions,
      }));
      
      setSavedQuestions(filteredData);
      
    } catch (error) {
      console.error("Failed to fetch saved alignments: ", error);
    }
  }, [user, getAccessTokenSilently, BASE_URL]);
  
  
  
  // This will refetch saved results whenever the user clicks the "Saved Results" section
  useEffect(() => {
    if (savedResultsExpanded && !hasFetchedBefore) {
      fetchSavedQuestions();
      setHasFetchedBefore(true); // Set the flag after fetching
    }
  }, [savedResultsExpanded, hasFetchedBefore, user, fetchSavedQuestions]);



//timer to pause a second then scroll to generative section
const scrollToGenerative = () => {
  setTimeout(() => {
    if (loadingRef.current) {
      window.scrollTo({
        top: loadingRef.current.offsetTop - 125,
        behavior: 'smooth',
      });
    }
  }, 25);
};


const handleToggleSavedResults = () => {
  setSavedResultsExpanded(prevState => !prevState);

  // If the saved results are not expanded, scroll to them
  if (!savedResultsExpanded) {
      setTimeout(() => {
          if (savedResultsRef.current) {
              window.scrollTo({
                  top: savedResultsRef.current.offsetTop - 100, // Offset by 100px
                  behavior: 'smooth',
              });
          }
      }, 25); // A short delay to ensure the section is rendered before scrolling
  }
};




  return (
    
    <div className="ComponentContainer">


        <h1 style={{marginLeft: 'auto', marginRight: 'auto'}}>Interview Questions Generator</h1>
        {/* <div className="button-3" onClick={() => setShowInstructions(!showInstructions)}> */}


        <div className="input-section">
          <div id="dropbox" className='db clickable'>
            <FileUpload onTextExtracted={(text) => setResume(text)} />
          </div>
        <div className='input-container'>
        <div className="input-form" style={{ 
        flex: showInstructions ? '0.7' : '1', 
        display: showInstructions ? 'column' : 'row' 
    }}>

            <div className="input-group">
            <div className='show-instructions' onClick={() => setShowInstructions(!showInstructions)}><AiFillInfoCircle></AiFillInfoCircle></div>

            <div className='titleandsave'>
              <label htmlFor="resumeInput" className='inputlabel'>Resume:</label>
            </div>


                          <textarea
                              id="resumeInput"
                              value={resume}
                              onChange={(e) => setResume(e.target.value)}
                              placeholder="Copy & paste the resume..."
                              className={`textarea-large ${focusedTextArea === 'resume' ? 'expanded' : ''}`}
                              maxLength={8000}
                              onFocus={() => setFocusedTextArea('resume')}
                              onBlur={() => setFocusedTextArea('')}
                          />

<div className="button-4" onClick={saveResumeButton}>Save</div> <div className="button-4" style={{marginLeft: '5px'}} onClick={clearResume}>Clear</div>



            </div>
            <div className="input-group">

              <div className='titleandsave'>
                  <label className='inputlabel'>Job You're Interested In: <br></br><h4>Title & Description</h4></label>
                  
              </div>



              <input
                id="jobTitleInput"
                type="text"
                value={jobTitle}
                onChange={(e) => setJobTitle(e.target.value)}
                placeholder="Enter job title..." />

              {/* JobDescription Input */}
                          <textarea
                              id="jobDescriptionInput"
                              value={jobDescription}
                              onChange={(e) => setJobDescription(e.target.value)}
                              placeholder="Copy & paste the job description..."
                              className={`textarea-large ${focusedTextArea === 'jobDescription' ? 'expanded' : ''}`}
                              maxLength={8000}
                              onFocus={() => setFocusedTextArea('jobDescription')}
                              onBlur={() => setFocusedTextArea('')}
                          />
<div className="button-4" onClick={saveJobDescriptionButton}>Save</div> <div className="button-4" style={{marginLeft: '5px'}} onClick={clearJobDescription}>Clear</div>

                      
            </div>


            <div className="button-group">
              <button type="button" className='button-2' onClick={generateInterviewQuestions}>Generate Interview Questions</button>
            </div>

          </div>


              <div className={showInstructions ? "instructions instructions-fadein" : "instructions instructions-fadeout"} style={{ flex: showInstructions ? 0.3 : '0' }}>
                  <div className='instructions-title'>
                      <h3>What to Expect:</h3>
                      <div className='instructions-close' onClick={() => setShowInstructions(false)}><AiOutlineClose></AiOutlineClose></div>

                  </div>

                  <li>Make sure your resume and job description are updated on the profile page.</li>
                  <li>Click 'Check Alignment'.</li>
                  <br></br>
                  <li>The system will evaluate the alignment of your resume with the job description.</li>
                  <li>You'll receive a detailed assessment of your qualifications versus job requirements.</li>

                  <p><strong>Note:</strong> For best results, ensure your resume and job description are current and accurate.</p>
              </div>

        </div>

      </div>



        
        

        <div className="generative-section" ref={loadingRef}>


          {isLoading && (
            <div className={`result-container ${showSkeleton ? 'fadein' : 'fadeout'}`}>
              <div className='result-inner'>
                <h2>Generating Questions...</h2><br></br>
                <h3>{jobTitle}</h3>
                
                <div className='divider'></div>
                <div className='textstream' ref={questionsRef}></div>


                <SkeletonLoader className="animated-background" />
              </div>
            </div>
          )}



         
   
          {!isProcessing && questions && (
            <div className={`result-container ${showContent ? 'fadein' : 'fadeout'}`}>
              <div className='result-inner'>
              <div className='title-close'>
                <h2> Generated Interview Questions:<br></br> {jobTitle}</h2>
                <div className='saved-close-button' onClick={() => deleteLocalResult()}>
                                        <AiOutlineClose></AiOutlineClose>
                                    </div>
                </div>
                <div className='divider'></div>
                <p dangerouslySetInnerHTML={{ __html: questions }}></p>
              </div>
              <div className='button-group'>
              <CopyToClipboardButton buttonClass='button-2' textToCopy={questions} />
              <button type="button" className='button-2' onClick={saveJobData}>Save</button> 
              <button type="button" className='button-2' onClick={generateInterviewQuestions}>Regenerate</button>
              </div>
            </div>
          )}

      


<div className='saved-result-section' ref={savedResultsRef}>
    <div className='save-title-expand'>
        <h1 onClick={handleToggleSavedResults}>Saved Results</h1>
        <div className='expand-collapse-saved-results' onClick={handleToggleSavedResults}>
            {savedResultsExpanded ? <AiOutlineClose></AiOutlineClose> : <AiOutlinePlus></AiOutlinePlus>}
        </div>
    </div>
    <div className='divider'></div>

    {savedResultsExpanded && savedQuestions.length > 0 && (
        <>
            {savedQuestions.map((item, index) => (
                item.interviewQuestions && (
                    <div key={index} className="saved-jobdata-item fadein">
                        <div className="result-container fadein">
                            <div className='result-inner'>
                                <div className='title-close'>
                                    <h2>{item.jobTitle}</h2>
                                    <div className='saved-close-button' onClick={() => deleteSavedResult('interviewQuestions', item.jobTitle)}>
                                        <AiOutlineClose></AiOutlineClose>
                                    </div>
                                </div>
                                <div className='divider'></div>
                                <p dangerouslySetInnerHTML={{ __html: item.interviewQuestions }}></p>
                                <CopyToClipboardButton buttonClass='button-1' textToCopy={item.interviewQuestions} />
                            </div>
                            <div className='divider'></div>
                        </div>
                    </div>
                )
            ))}
        </>
    )}
</div>

        </div>

      </div>
  );
};

export default InterviewQuestionsGenerator;
