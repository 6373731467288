import React from 'react';
import './SkeletonLoader.css';
import './App.css';

const SkeletonLoader = () => {
    return (


          <div className='skeleton'>
            <div className="animated-background"></div>
            <div className="animated-background"></div>

        </div>


    );
}

export default SkeletonLoader;
