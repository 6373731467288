import React, { useState, useEffect } from 'react';
import FileUpload from './FileUpload';
import './App.css';
import CopyToClipboardButton from './CopyToClipboardButton';
import { useAuth0 } from "@auth0/auth0-react";
import SkeletonLoader from './SkeletonLoader';
import { AiOutlineClose } from 'react-icons/ai';
import { AiFillInfoCircle } from 'react-icons/ai';




const ResumeFeedback = ({ baseUrl, setMessage, setMessageType, setIsLoading, isLoading, loadingMessage, setLoadingMessage }) => {
  const BASE_URL = baseUrl;


  const { getAccessTokenSilently, user } = useAuth0();

  //states set for functions
  const [resume, setResume] = useState('');
  const [feedback, setFeedback] = useState('');
  const [newresume, setNewResume] = useState('');

  

// instructions variable and state for showing and hiding instructions
  const [showInstructions, setShowInstructions] = useState(true);


  //transition rendering of result

const [showSkeleton, setShowSkeleton] = useState(false);
const [showContent, setShowContent] = useState(false);

//state for text-area expansion
const [isExpanded, setIsExpanded] = useState(false); // New state variable for expand/collapse



//state for saved feedback


//ref for scroll to loading
const loadingRef = React.useRef(null);

//ref for scroll to saved results


//ref for stream of feedback and new resume
const feedbackRef = React.useRef(null);
const newresumeRef = React.useRef(null);





useEffect(() => {
  if (isLoading) {
    setShowSkeleton(true);
  } else {
    // Start fade-out of the skeleton
    setShowSkeleton(false);
    // After the skeleton fades out, start the fade-in of the content
    setTimeout(() => {
      setShowContent(true);
    }, 500);
  }
}, [isLoading]);





  const resetErrors = () => {
    setMessage('');
  };

  //useeffect for fetching user data from db and setting state variables for resume
  useEffect(() => {
    const fetchUserData = async () => {
      if (user && user.email) {
        const token = await getAccessTokenSilently({
          audience: "https://dev-jobsai.us.auth0.com/api/v2/",
          scope: "openid profile email",
        });

        try {
          const response = await fetch(`${BASE_URL}/users/find-user?email=${user.email}&auth0Id=${user.sub}&name=${user.name}`, {
            headers: {
              'Authorization': `Bearer ${token}`,
            },
          });

          if (response.ok) {
            const data = await response.json();
            if (data.resume) {
              setResume(data.resume);
            }

          } else {
            const errorData = await response.json();
            setMessage(errorData.error || 'Failed to fetch user data.');
            setMessageType('error');
          }
        } catch (error) {
          setMessage(error.message);
          setMessageType('error');
        }
      }
    };
    fetchUserData();
  }, [user, getAccessTokenSilently, BASE_URL, setMessage, setMessageType]);


//Save Resume For Input Area to Profile db
  const saveResume = async () => {
    try {
      const token = await getAccessTokenSilently({
        audience: "https://dev-jobsai.us.auth0.com/api/v2/",
        scope: "openid profile email",
      });

      const response = await fetch(`${BASE_URL}/users/modify-profile`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: user.email, resume }),
      });

      if (response.ok) {
        setMessage('Resume Saved Successfully.');
        setMessageType('success');
      } else {
        const errorData = await response.json();
        setMessage(errorData.error || 'Failed to save resume.');
        setMessageType('error');
      }
    } catch (error) {
      setMessage(error.message);
      setMessageType('error');
    }
  };

  //clear resume from local
  const clearResume = () => {
    setResume('');
  };

//gets feedback on resume
const checkResumeFeedback = async () => {
  resetErrors();

  if (!resume) {
      setMessage('Please make sure to populate the resume field.');
      setMessageType('error');
      return;
  }

  try {
    setIsLoading(true);
    scrollToGenerative();

      if (loadingRef.current) {
          window.scrollTo({
              top: loadingRef.current.offsetTop,
              behavior: 'smooth',
          });
      }

      const token = await getAccessTokenSilently({
          audience: "https://dev-jobsai.us.auth0.com/api/v2/",
          scope: "openid profile email",
      });
      
      const response = await fetch(`${BASE_URL}/generate-resume-feedback`, {
          method: 'POST',
          headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
          },
          body: JSON.stringify({ resume }),
      });

      const reader = response.body.getReader();
      const textDecoder = new TextDecoder();

      while (true) {
          const { value, done } = await reader.read();
          if (done) break;

          // Append directly to the DOM element
          if (feedbackRef.current) {
              feedbackRef.current.innerHTML += textDecoder.decode(value, { stream: true });
          }

      }

      // Set the feedback state variable with the content of the feedbackRef
      if (feedbackRef.current) {
          setFeedback(feedbackRef.current.innerHTML);
      }

  } catch (error) {
      setMessage('Error fetching feedback: ' + error.message);
      setMessageType('error');
  }
  finally {
      setIsLoading(false);
      setLoadingMessage('');
  }
}

//generates resume based on feedback
const generateResumeFromFeedback = async () => {
  resetErrors();

  if (!feedback) {
      setMessage('Please make sure to populate the feedback field.');
      setMessageType('error');
      return;
  }

  try {
      setIsLoading(true);
      scrollToGenerative();

      if (loadingRef.current) {
          window.scrollTo({
              top: loadingRef.current.offsetTop,
              behavior: 'smooth',
          });
      }

      const token = await getAccessTokenSilently({
          audience: "https://dev-jobsai.us.auth0.com/api/v2/",
          scope: "openid profile email",
      });

      const response = await fetch(`${BASE_URL}/generate-resume-from-feedback`, {
          method: 'POST',
          headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
          },
          body: JSON.stringify({ feedback, resume }),
      });

      const reader = response.body.getReader();
      const textDecoder = new TextDecoder();

      while (true) {

          const { value, done } = await reader.read();
          if (done) break;

          // Append directly to the DOM element
          if (newresumeRef.current) {
            newresumeRef.current.innerHTML += textDecoder.decode(value, { stream: true });
        }
        

          console.log("Received chunk:", textDecoder.decode(value));
      }

      // Set the feedback state variable with the content of the feedbackRef
      if (newresumeRef.current) {
          setNewResume(newresumeRef.current.innerHTML);
      }

  } catch (error) {
      setMessage('Error generating new resume: ' + error.message);
      setMessageType('error');
  }
  finally {

      setIsLoading(false);
      setLoadingMessage('');
  }
}




//timer to pause a second then scroll to generative section
const scrollToGenerative = () => {
  setTimeout(() => {
    if (loadingRef.current) {
      window.scrollTo({
        top: loadingRef.current.offsetTop - 125,
        behavior: 'smooth',
      });
    }
  }, 25);
};







  return (
    
    <div className="ComponentContainer">


        <h1 style={{marginLeft: 'auto', marginRight: 'auto'}}>Resume Feedback</h1>
        {/* <div className="button-3" onClick={() => setShowInstructions(!showInstructions)}> */}


        <div className="input-section">
          <div id="dropbox" className='db clickable'>
            <FileUpload onTextExtracted={(text) => setResume(text)} />
          </div>
        <div className='input-container resume' style={{ 
        flexDirection: showInstructions ? 'column' : 'row' }}>
        
    
        <div className="input-form resume" >

            <div className="input-group">
            <div className='show-instructions' onClick={() => setShowInstructions(!showInstructions)}><AiFillInfoCircle></AiFillInfoCircle></div>

            <div className='titleandsave'>
              <label htmlFor="resumeInput" className='inputlabel'>Resume:</label>
            </div>


                          <textarea
                              id="resumeInput"
                              value={resume}
                              onChange={(e) => setResume(e.target.value)}
                              placeholder="Copy & paste the resume..."
                              className={`${isExpanded ? 'textarea-verylarge' : 'textarea-large'} expanded`} // Updated className
                              maxLength={8000}

                          />

<div className="button-4" onClick={saveResume}>Save</div> <div className="button-4" style={{marginLeft: '5px'}} onClick={clearResume}>Clear</div> 
<div className="button-4" style={{marginLeft: '5px'}} onClick={() => setIsExpanded(true)}>Expand</div> <div className="button-4" style={{marginLeft: '5px'}} onClick={() => setIsExpanded(false)}>Collapse</div> 



            </div>


            <div className="button-group">
              <button type="button" className='button-2' onClick={checkResumeFeedback}>Generate Feedback</button>
            </div>

          </div>


              <div className={showInstructions ? "instructions instructions-fadein resume" : "instructions instructions-fadeout resume" } style={{ flex: showInstructions ? 0.3 : '0' }}>
                  <div className='instructions-title'>
                      <h3>What to Expect:</h3>
                      <div className='instructions-close' onClick={() => setShowInstructions(false)}><AiOutlineClose></AiOutlineClose></div>
                  </div>

                  <li>Make sure your resume is updated on the profile page.</li>
                  <li>Click 'Generate Feedback'.</li>
                  <br></br>
                  <li>The system will evaluate the your resume.</li>
                  <li>You'll receive a detailed assessment what works and what doesn't for your resume.</li>
                  <li>Use the feedback to improve your resume manually, or allow GPT to make the changes for you</li>
                  <li>Click Generate Resume From Feedback</li>

                  <p><strong>Note:</strong> For best results, ensure your resume is current and accurate.</p>
              </div>

        </div>

      </div>



        
        

        <div className="generative-section" ref={loadingRef}>


          {isLoading && (
            <div className={`result-container ${showSkeleton ? 'fadein' : 'fadeout'}`}>
              <div className='result-inner'>
                <h2>Generating Resume Feedback...</h2>
                
                <div className='divider'></div>
                <div className='textstream' ref={feedbackRef}></div>
                <div className='textstream' ref={newresumeRef}></div>


                <SkeletonLoader className="animated-background" />
              </div>
            </div>
          )}


          {newresume && (
                      <div className={`result-container ${showContent ? 'fadein' : 'fadeout'}`}>
                        <div className='result-inner'>
                          <h2> Re-Written Resume:<br></br></h2>
                          <div className='divider'></div>
                          <p dangerouslySetInnerHTML={{ __html: newresume }}></p>
                        </div>
                        <div className='button-group'>
                        <CopyToClipboardButton buttonClass='button-2' textToCopy={newresume} />
                        </div>
                      </div>
                    )}

         
   
          {feedback && (
            <div className={`result-container ${showContent ? 'fadein' : 'fadeout'}`}>
              <div className='result-inner'>
                <h2> Resume Feedback:<br></br></h2>
                <div className='divider'></div>
                <p dangerouslySetInnerHTML={{ __html: feedback }}></p>
              </div>
              <div className='button-group'>
              <CopyToClipboardButton buttonClass='button-2' textToCopy={feedback} />
              <button type="button" className='button-2' onClick={generateResumeFromFeedback}>Generate Resume From Feedback</button>
              </div>
            </div>
          )}


          

      
        </div>

      </div>
  );
};

export default ResumeFeedback;
