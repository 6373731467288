import React, { useState, useEffect, useCallback } from 'react';
import FileUpload from './FileUpload';
import './App.css';
import CopyToClipboardButton from './CopyToClipboardButton';
import { useAuth0 } from "@auth0/auth0-react";
import SkeletonLoader from './SkeletonLoader';
import { AiOutlineClose } from 'react-icons/ai';
import { AiOutlinePlus } from 'react-icons/ai';
import { AiFillInfoCircle } from 'react-icons/ai';




const CoverLetterGenerator = ({ baseUrl, setMessage, setMessageType, setIsLoading, isLoading, loadingMessage, setLoadingMessage }) => {
  const BASE_URL = baseUrl;


  const { getAccessTokenSilently, user } = useAuth0();

  //states set for functions
  const [resume, setResume] = useState('');
  const [jobDescription, setJobDescription] = useState('');
  const [coverletter, setCoverLetter] = useState(null);
  const [jobTitle, setJobTitle] = useState(''); // State variable for job title

  

// instructions variable and state for showing and hiding instructions
  const [showInstructions, setShowInstructions] = useState(true);


  //transition rendering of result

const [showSkeleton, setShowSkeleton] = useState(false);
const [showContent, setShowContent] = useState(false);


//state for saved CoverLetters
const [savedCoverLetters, setSavedCoverLetters] = useState([]);


//ref for scroll to loading
const loadingRef = React.useRef(null);

//ref for scroll to saved results
const savedResultsRef = React.useRef(null);



//expandable saved results section
const [savedResultsExpanded, setSavedResultsExpanded] = useState(false);

// Flag to track whether the user has fetched saved alignments before
const [hasFetchedBefore, setHasFetchedBefore] = useState(false);

// State variable for the textarea focus expander
const [focusedTextArea, setFocusedTextArea] = useState('');



  //local state for flagging processing
  const [isProcessing, setIsProcessing] = useState(false);



const coverletterRef = React.useRef(null);

//making cover letter editable and saved to local storage
const handleCoverLetterEdit = () => {
  if (coverletterRef.current) {
      const editedContent = coverletterRef.current.innerHTML;
      setCoverLetter(editedContent);
      // Optionally, update localStorage or any other state/logic as needed
      localStorage.setItem('coverletter', editedContent);
  }
};





useEffect(() => {
  if (isLoading) {
    setShowSkeleton(true);
  } else {
    // Start fade-out of the skeleton
    setShowSkeleton(false);
    // After the skeleton fades out, start the fade-in of the content
    setTimeout(() => {
      setShowContent(true);
    }, 500);
  }
}, [isLoading]);

//deletes local storage result
const deleteLocalResult = () => {
  setCoverLetter(null);
  setShowContent(false);
  setShowSkeleton(false);
};



  const resetErrors = () => {
    setMessage('');
  };

  //useeffect for fetching user data from db and setting state variables for resume, job description, and job title
  useEffect(() => {
    const fetchUserData = async () => {
      if (user && user.email) {
        const token = await getAccessTokenSilently({
          audience: "https://dev-jobsai.us.auth0.com/api/v2/",
          scope: "openid profile email",
        });

        try {
          const response = await fetch(`${BASE_URL}/users/find-user?email=${user.email}&auth0Id=${user.sub}&name=${user.name}`, {
            headers: {
              'Authorization': `Bearer ${token}`,
            },
          });

          if (response.ok) {
            const data = await response.json();
            if (data.resume) {
              setResume(data.resume);
            }
            if (data.jobDescription) {
              setJobDescription(data.jobDescription);
            }
            if (data.jobTitle) {
              setJobTitle(data.jobTitle);
            }

          } else {
            const errorData = await response.json();
            setMessage(errorData.error || 'Failed to fetch user data.');
            setMessageType('error');
          }
        } catch (error) {
          setMessage(error.message);
          setMessageType('error');
        }
      }
    };
    fetchUserData();

      const savedCoverLetter = localStorage.getItem('coverletter');
      if (savedCoverLetter) {
        setCoverLetter(savedCoverLetter);
      }

  }, [user, getAccessTokenSilently, BASE_URL, setMessage, setMessageType]);


//save job description to db
  const saveJobDescription = async () => {
    try {
      const token = await getAccessTokenSilently({
        audience: "https://dev-jobsai.us.auth0.com/api/v2/",
        scope: "openid profile email",
      });

      const response = await fetch(`${BASE_URL}/users/modify-profile`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: user.email, jobDescription, jobTitle }), // Include jobTitle here
      });

      if (response.ok) {

      } else {
        const errorData = await response.json();
        setMessage(errorData.error || 'Failed to Save Job Description.');
        setMessageType('error');
      }
    } catch (error) {
      setMessage(error.message);
      setMessageType('error');
    }
  };

  //save job description button function which will give a confirmation of success or failure

  const saveJobDescriptionButton = async () => {
    saveJobDescription();
    //if job description state has changed give success
    if (jobDescription) {
      setMessage('Job Description Saved Successfully.');
      setMessageType('success');
    }
    else {
      setMessage('Failed to Save Job Description.');
      setMessageType('error');
    }
  };


  const saveResume = async () => {
    try {
      const token = await getAccessTokenSilently({
        audience: "https://dev-jobsai.us.auth0.com/api/v2/",
        scope: "openid profile email",
      });

      const response = await fetch(`${BASE_URL}/users/modify-profile`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: user.email, resume }),
      });

      if (response.ok) {
        
      } else {
        const errorData = await response.json();
        setMessage(errorData.error || 'Failed to save resume.');
        setMessageType('error');
      }
    } catch (error) {
      setMessage(error.message);
      setMessageType('error');
    }
  };

  //save resume button function which will give a confirmation of success or failure

  const saveResumeButton = async () => {
    saveResume();
    if (resume) {
      setMessage('Resume Saved Successfully.');
      setMessageType('success');
    } else {
      setMessage('Failed to Save Resume.');
      setMessageType('error');
    }
  };

  //clear job description and title from local
  const clearJobDescription = () => {
    setJobDescription('');
    setJobTitle('');
  };

  //clear resume from local
  const clearResume = () => {
    setResume('');
  };



  const generateCoverLetter = async () => {
//if isProcessing is active, throw an error to wait till job is done
    if (isProcessing) {
      setMessage('Please wait for the current process to finish.');
      setMessageType('warning');
      return;
    }

    resetErrors();

    if (!jobDescription || !resume) {
        setMessage('Please make sure to populate both the job description and resume fields.');
        setMessageType('error');
        return;
    }


    try {
        saveResume();
        saveJobDescription();
        setIsProcessing(true);
        setIsLoading(true);
        scrollToGenerative();

        if (loadingRef.current) {
            window.scrollTo({
                top: loadingRef.current.offsetTop,
                behavior: 'smooth',
            });
        }

        const token = await getAccessTokenSilently({
            audience: "https://dev-jobsai.us.auth0.com/api/v2/",
            scope: "openid profile email",
        });

        const response = await fetch(`${BASE_URL}/generate-cover-letter`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ jobDescription, resume }),
        });

        const reader = response.body.getReader();
        const textDecoder = new TextDecoder();

        while (true) {
            const { value, done } = await reader.read();
            if (done) break;

            // Append directly to the DOM element
            if (coverletterRef.current) {
                coverletterRef.current.innerHTML += textDecoder.decode(value, { stream: true });
            }

            console.log("Received chunk:", textDecoder.decode(value));
        }

        // Set the alignment state variable with the content of the alignmentRef
        if (coverletterRef.current) {
                    const fetchedCoverLetter = coverletterRef.current.innerHTML;
                    setCoverLetter(fetchedCoverLetter);
                    //save to local storage
                    localStorage.setItem('coverletter', fetchedCoverLetter);
                               }

    } catch (error) {
        setMessage('Error fetching Cover Letter: ' + error.message);
        setMessageType('error');
    } finally {
        setIsLoading(false);
        setIsProcessing(false);
        setLoadingMessage('');

    }
}


//save job data to db
const saveJobData = async () => {
  const token = await getAccessTokenSilently({
    audience: "https://dev-jobsai.us.auth0.com/api/v2/",
    scope: "openid profile email",
  });

  const jobData = {
    userId: user.sub,
    jobTitle,
    jobDescription,
    coverLetter: coverletter,
  };

  // Check if there's already a saved alignment with the same content
  if (savedCoverLetters.some(item => item.CoverLetter === coverletter)) {
    setMessage(`Job data for ${jobTitle} already exists. Please change the job title or regenerate the existing entry.`);
    setMessageType("error");
    return; // Exit early from the function
  }

  try {
    const response = await fetch(`${BASE_URL}/users/saveJobData`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(jobData),
    });

    const responseData = await response.json();

    if (response.status === 200 || response.status === 201) {
      setMessage("Job data successfully saved.");
      setMessageType("success");
      setSavedCoverLetters([{ jobTitle, coverLetter: coverletter }, ...savedCoverLetters]);
      //clear saved result from local
      localStorage.removeItem('coverletter');
      setSavedResultsExpanded(true);
      deleteLocalResult();
      //scrollto saved results
      setTimeout(() => {
            window.scrollTo({
                top: savedResultsRef.current.offsetTop -0, // Offset by 25px
                behavior: 'smooth',
            });
        }
        , 50); // A short delay to ensure the section is rendered before scrolling

    } else {
      setMessage(`Failed to save job data: ${responseData.message}`);
      setMessageType("error");
    }

  } catch (error) {
    setMessage(`An error occurred: ${error.message}`);
    setMessageType("error");
  }
};


//deletes saved result from jobdata object
  const deleteSavedResult = async (componentName, dataIdentifier) => {

            const isConfirmed = window.confirm(`Are you sure you want to delete the data for ${dataIdentifier}?`);
          
          // If the user clicked "Cancel", then exit the function
          if (!isConfirmed) {
            return;
          }

    try {
        const token = await getAccessTokenSilently({
            audience: "https://dev-jobsai.us.auth0.com/api/v2/",
            scope: "openid profile email",
        });

        const response = await fetch(`${BASE_URL}/users/deleteSavedResult`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ userId: user.sub, componentName, dataIdentifier }),
        });

        if (response.ok) {
            // Remove the deleted alignment from the state
            setSavedCoverLetters(prevCoverLetters => prevCoverLetters.filter(item => item.jobTitle !== dataIdentifier));

            setMessage(`${componentName} data deleted successfully.`);
            setMessageType('success');
        } else {
            const errorData = await response.json();
            setMessage(errorData.error || `Failed to delete ${componentName} data.`);
            setMessageType('error');
        }
    } catch (error) {
        setMessage(`An error occurred: ${error.message}`);
        setMessageType("error");
    }
};


  
  //fetches saved alignments and job title
  
  const fetchSavedCoverLetters = useCallback(async () => {
    const token = await getAccessTokenSilently({
      audience: "https://dev-jobsai.us.auth0.com/api/v2/",
      scope: "openid profile email",
    });
    
    try {
      const response = await fetch(`${BASE_URL}/users/getAllJobData/${user.sub}?componentName=coverLetter`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      
      const data = await response.json();
      
      // Only keep the 'jobTitle' and 'resumeAlignment' fields for each item
      const filteredData = data.jobDataList.map(item => ({
        jobTitle: item.jobTitle,
        coverLetter: item.coverLetter,
      }));
      
      setSavedCoverLetters(filteredData);
      
    } catch (error) {
      console.error("Failed to fetch saved cover letter: ", error);
    }
  }, [user, getAccessTokenSilently, BASE_URL]);
  
  
  
  // This will refetch saved alignments whenever the user clicks the "Saved Results" section
  useEffect(() => {
    if (savedResultsExpanded && !hasFetchedBefore) {
      fetchSavedCoverLetters();
      setHasFetchedBefore(true); // Set the flag after fetching
    }
  }, [savedResultsExpanded, hasFetchedBefore, user, fetchSavedCoverLetters]);




//timer to pause a second then scroll to generative section
const scrollToGenerative = () => {
  setTimeout(() => {
    if (loadingRef.current) {
      window.scrollTo({
        top: loadingRef.current.offsetTop - 125,
        behavior: 'smooth',
      });
    }
  }, 25);
};



const handleToggleSavedResults = () => {
  setSavedResultsExpanded(prevState => !prevState);

  // If the saved results are not expanded, scroll to them
  if (!savedResultsExpanded) {
      setTimeout(() => {
          if (savedResultsRef.current) {
              window.scrollTo({
                  top: savedResultsRef.current.offsetTop - 100, // Offset by 100px
                  behavior: 'smooth',
              });
          }
      }, 25); // A short delay to ensure the section is rendered before scrolling
  }
};




  return (
    
    <div className="ComponentContainer">


        <h1 style={{marginLeft: 'auto', marginRight: 'auto'}}>Cover Letter Generator</h1>
        {/* <div className="button-3" onClick={() => setShowInstructions(!showInstructions)}> */}


        <div className="input-section">
          <div id="dropbox" className='db clickable'>
            <FileUpload onTextExtracted={(text) => setResume(text)} />
          </div>
        <div className='input-container'>
        <div className="input-form" style={{ 
        flex: showInstructions ? '0.7' : '1', 
        display: showInstructions ? 'column' : 'row' 
    }}>

            <div className="input-group">
            <div className='show-instructions' onClick={() => setShowInstructions(!showInstructions)}><AiFillInfoCircle></AiFillInfoCircle></div>

            <div className='titleandsave'>
              <label htmlFor="resumeInput" className='inputlabel'>Resume:</label>
            </div>


                          <textarea
                              id="resumeInput"
                              value={resume}
                              onChange={(e) => setResume(e.target.value)}
                              placeholder="Copy & paste the resume..."
                              className={`textarea-large ${focusedTextArea === 'resume' ? 'expanded' : ''}`}
                              maxLength={8000}
                              onFocus={() => setFocusedTextArea('resume')}
                              onBlur={() => setFocusedTextArea('')}
                          />

<div className="button-4" onClick={saveResumeButton}>Save</div> <div className="button-4" style={{marginLeft: '5px'}} onClick={clearResume}>Clear</div>



            </div>
            <div className="input-group">

              <div className='titleandsave'>
                  <label className='inputlabel'><h4>Company & Title</h4></label>
                  
              </div>



              <input
                id="jobTitleInput"
                type="text"
                value={jobTitle}
                onChange={(e) => setJobTitle(e.target.value)}
                placeholder="Enter job title..." />

                <h4 style={{marginTop: '10px'}}>Description:</h4>

             
              {/* JobDescription Input */}
                          <textarea
                              id="jobDescriptionInput"
                              value={jobDescription}
                              onChange={(e) => setJobDescription(e.target.value)}
                              placeholder="Copy & paste the job description..."
                              className={`textarea-large ${focusedTextArea === 'jobDescription' ? 'expanded' : ''}`}
                              maxLength={8000}
                              onFocus={() => setFocusedTextArea('jobDescription')}
                              onBlur={() => setFocusedTextArea('')}
                              style={{marginTop: '0px'}}
                          />
<div className="button-4" onClick={saveJobDescriptionButton}>Save</div> <div className="button-4" style={{marginLeft: '5px'}} onClick={clearJobDescription}>Clear</div>

                      
            </div>


            <div className="button-group">
              <button type="button" className='button-2' onClick={generateCoverLetter}>Generate Cover Letter</button>
            </div>

          </div>


              <div className={showInstructions ? "instructions instructions-fadein" : "instructions instructions-fadeout"} style={{ flex: showInstructions ? 0.3 : '0' }}>
                  <div className='instructions-title'>
                      <h3>What to Expect:</h3>
                      <div className='instructions-close' onClick={() => setShowInstructions(false)}><AiOutlineClose></AiOutlineClose></div>
                  </div>

                  <li>Make sure your resume and job description are updated on the profile page.</li>
                  <li>Click 'Generate Cover Letter'.</li>
                  <br></br>
                  <li>The system will evaluate the alignment of your resume with the job description.</li>
                  <li>You'll receive a detailed assessment of your qualifications versus job requirements.</li>

                  <p><strong>Note:</strong> For best results, ensure your resume and job description are current and accurate.</p>
              </div>

        </div>

      </div>



        
        

        <div className="generative-section" ref={loadingRef}>


          {isProcessing && (
            <div className={`result-container ${showSkeleton ? 'fadein' : 'fadeout'}`}>
              <div className='result-inner'>
                <h2>Generating Results...</h2>
                <h3>{jobTitle}</h3>
                
                <div className='divider'></div>
                <div className='textstream' ref={coverletterRef}></div>


                <SkeletonLoader className="animated-background" />
              </div>
            </div>
          )}



         
   
          {!isProcessing && coverletter && (
            <div className={`result-container ${showContent ? 'fadein' : 'fadeout'}`}>
              <div className='result-inner'> 
              <div className='title-close'>
                <h2> Cover Letter:<br></br> {jobTitle}</h2>
                                   <div className='saved-close-button' onClick={() => deleteLocalResult()}>
                                        <AiOutlineClose></AiOutlineClose>
                                    </div>
                </div>
                <div className='divider'></div>



                <div contentEditable 
                onBlur={handleCoverLetterEdit} 
                ref={coverletterRef} 
                dangerouslySetInnerHTML={{ __html: coverletter }}>
                  
                </div>



              </div>
              <div className='button-group'>
              <CopyToClipboardButton buttonClass='button-2' textToCopy={coverletter} />
              <button type="button" className='button-2' onClick={saveJobData}>Save</button> 
              <button type="button" className='button-2' onClick={generateCoverLetter}>Regenerate</button>
              </div>
            </div>
          )}

      


<div className='saved-result-section' ref={savedResultsRef}>
    <div className='save-title-expand'>
        <h1 onClick={handleToggleSavedResults}>Saved Results</h1>
        <div className='expand-collapse-saved-results' onClick={handleToggleSavedResults}>
            {savedResultsExpanded ? <AiOutlineClose></AiOutlineClose> : <AiOutlinePlus></AiOutlinePlus>}
        </div>
    </div>
    <div className='divider'></div>

    {savedResultsExpanded && savedCoverLetters.length > 0 && (
        <>
            {savedCoverLetters.map((item, index) => (
                item.coverLetter && (
                    <div key={index} className="saved-jobdata-item fadein">
                        <div className="result-container fadein">
                            <div className='result-inner'>
                                <div className='title-close'>
                                    <h2>{item.jobTitle}</h2>
                                    <div className='saved-close-button' onClick={() => deleteSavedResult('coverLetter', item.jobTitle)}>
                                        <AiOutlineClose></AiOutlineClose>
                                    </div>
                                </div>
                                <div className='divider'></div>
                                <p dangerouslySetInnerHTML={{ __html: item.coverLetter }}></p>
                                <CopyToClipboardButton buttonClass='button-1' textToCopy={item.coverLetter} />
                            </div>
                            <div className='divider'></div>
                        </div>
                    </div>
                )
            ))}
        </>
    )}
</div>

        </div>

      </div>
  );
};

export default CoverLetterGenerator;
